import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import isCarerVar from "../apollo/vars/isCarerVar"
import CormiePageContainer from "../layouts/CormiePageContainer"
import PageTitle from "../components/PageTitle"
import UserInformation from "../components/UserInformation"
import {
  useUserProfileLazyQuery,
  useLocalUserQuery,
} from "../generated/graphql"
import { useLocation, useNavigate } from "react-router-dom"
import Loading from "../components/Loading"
import CormieSection from "../layouts/CormieSection"
import CormieSectionTitle from "../layouts/CormieSectionTitle"
import UserTags from "../components/UserTags"
import UserOrganisation from "../components/UserOrganisation"
import UserPermacart from "../components/UserPermacart"
import UserBillingInformation from "../components/UserBillingInformation"
import CormieProfileEditButton from "../components/CormieProfileEditButton"
import CarerUserInformation from "../components/CarerUserInformation"
import isAdminVar from "../apollo/vars/isAdminVar"
import OrdersInterface from "../components/OrdersInterface"
import { correctPlural } from "../utils/user/correctPlural"

const Profile: React.FC<{
  user: "client" | "carer" | "local"
}> = ({ user }) => {
  const location = useLocation()
  const isCarer = isCarerVar()
  const isAdmin = isAdminVar()
  const navigate = useNavigate()

  // Get the UID from the path if it exists, otherwise var is ""
  const splitPath = location.pathname.split("/")
  const uid =
    splitPath[
      splitPath.findIndex((val) => {
        switch (user) {
        case "client":
          return val === "customers"
        case "carer":
          return val === "employees"
        }
      }) + 1
    ]

  const [hasScrolled, setHasScrolled] = useState(false)

  const [
    clientProfileDataQuery,
    { data: clientCarerProfile, loading: clientCarerLoading },
  ] = useUserProfileLazyQuery()

  const { data: localProfile, loading: localProfileLoading } =
    useLocalUserQuery()

  let clientId = ""
  let carerId = ""
  // If we're pulling the "user profile" page
  if (user === "local") {
    if (isAdmin || isCarer) {
      // If user is admin or carer, we want to load all the orders placed by that user.
      carerId = localProfile?.me?.id || ""
    } else {
      // The client is pulling their own orders.
      clientId = localProfile?.me?.id || ""
    }
  }

  // If we're pulling a "carer profile" page
  if (user === "carer") {
    if (isAdmin || isCarer) {
      // If user is admin or carer, we want to load all the orders placed by the requested user.
      carerId = uid
    } else {
      // Throw and error, as a client should not have access to this page
      throw new Error(
        `Unpermitted user ID: ${
          localProfile?.me?.id || "undefined"
        } accessed restricted path: ${location.pathname}`,
      )
    }
  }

  // If we're pulling a "client profile" page
  if (user === "client") clientId = uid

  useEffect(() => {
    switch (user) {
    case "client":
    case "carer":
      clientProfileDataQuery({
        variables: { id: uid },
      })
      break
    }
  }, [])

  const userLoading =
    user === "local" ? localProfileLoading : clientCarerLoading
  const userProfile =
    user === "local" ? localProfile?.me : clientCarerProfile?.extendedUser

  // Scroll to anchor link if applicable
  const { hash } = window.location
  if (hash != "" && !hasScrolled && userProfile) {
    setTimeout(() => {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView()
        // Full page rendering doesn't technically complete until
        // lazy loaded components finish loading. However, if we wait
        // for those components before we scroll the page feels weird
        // slash sluggish. We instead let the scroll hook fire as many
        // times as it wants for two seconds, then disable it. Otherwise,
        // future re-renders will cause the page to scroll again
        setTimeout(() => {
          if (!hasScrolled) setHasScrolled(true)
        }, 2000)
      }
    }, 0)
  }
  return (
    <CormiePageContainer>
      {(!userProfile) ? (
        <Loading className="mt-5" />
      ) : (
        <>
          {/* User Details Row */}
          <Row>
            <Col>
              <PageTitle className="mb-3">{userProfile?.fullName}</PageTitle>
            </Col>
          </Row>
          <Row xs={1} xxl={2}>
            <Col>
              <CormieSection left>
                <CormieSectionTitle
                  editable
                  editCallback={() => {
                    navigate(
                      user === "local"
                        ? "/profile/edit-profile/#userDetails"
                        : `/${
                          user === "carer" ? "employees/" : "customers/"
                        }${uid}/edit-${
                          user === "client" ? "customer" : "employee"
                        }/#userDetails`,
                    )
                  }}
                >
                  {`${
                    user === "client"
                      ? "Client"
                      : user === "carer"
                        ? "Employee"
                        : "Your"
                  } information`}
                </CormieSectionTitle>
                {user === "carer" ||
                (user === "local" && (isCarer || isAdmin)) ? (
                    <CarerUserInformation user={userProfile} isEdit={false} />
                  ) : (
                    <UserInformation user={userProfile} isEdit={false} />
                  )}
              </CormieSection>
            </Col>
            <Col>
              <Col>
                {(isCarer || isAdmin) && (
                  <>
                    <CormieSection right>
                      <CormieSectionTitle
                        editable
                        editCallback={() => {
                          navigate(
                            user === "local"
                              ? "/profile/edit-profile/#userTags"
                              : `/${
                                user === "carer" ? "employees/" : "customers/"
                              }${uid}/edit-${
                                user === "client" ? "customer" : "employee"
                              }/#userTags`,
                          )
                        }}
                      >
                        <span id={"userTags"}>
                          {`${
                            user === "client"
                              ? "Client"
                              : user === "carer"
                                ? "Employee"
                                : "Your"
                          } tags`}
                        </span>
                      </CormieSectionTitle>
                      <UserTags userTags={userProfile?.routeTags} />
                    </CormieSection>
                    <CormieSection right>
                      <CormieSectionTitle>
                        {"Organisation details"}
                      </CormieSectionTitle>
                      <UserOrganisation user={userProfile} />
                    </CormieSection>
                  </>
                )}

                {(user === "client" ||
                  user === "local" &&
                  !(isCarer || isAdmin)
                ) && (
                  <CormieSection right>
                    <CormieSectionTitle
                      editable
                      editCallback={() => {
                        navigate(
                          user === "local"
                            ? "/profile/edit-profile/#deliveryInstructions"
                            : `/customers/${uid}/edit-${
                              user === "client" ? "customer" : "employee"
                            }/#deliveryInstructions`,
                        )
                      }}
                    >
                      <span id={"deliveryInstructions"}>
                        {"Delivery instructions"}
                      </span>
                    </CormieSectionTitle>
                    {userProfile?.deliveryInstructions ? (
                      <>{userProfile?.deliveryInstructions}</>
                    ) : (
                      "No special delivery instructions provided"
                    )}
                  </CormieSection>
                )}
              </Col>
            </Col>
          </Row>
          {/* User Permacart row */}
          {(user === "client" ||
            user === "local" &&
            !(isCarer || isAdmin)
          ) && (
            <>
              <Row className="justify-content-between mt-5">
                <Col>
                  <h1 id={"permacart"}>
                    {(user === "local"
                      ? "Your"
                      : correctPlural(userProfile?.fullName || "")
                    ) + " favourite products"}
                  </h1>
                </Col>
                <CormieProfileEditButton
                  className="mb-2 mb-sm-0"
                  editCallback={() => {
                    navigate(
                      user === "local"
                        ? "/profile/edit-profile/#permacart"
                        : `/customers/${uid}/edit-${
                          user === "client" ? "customer" : "employee"
                        }/#permacart`,
                    )
                  }}
                  editText={
                    userProfile?.permacartOrderDayInterval
                      ? "stop or edit"
                      : userProfile?.permacartLineItems &&
                        userProfile.permacartLineItems.length
                        ? "edit"
                        : "edit"
                  }
                />
              </Row>
              <Row>
                <Col>
                  <CormieSection>
                    <UserPermacart
                      permacartLineItems={userProfile?.permacartLineItems}
                      permacartLoading={userLoading}
                      permacartOrderDayInterval={
                        userProfile?.permacartOrderDayInterval
                      }
                      permacartTriggersInNDays={
                        userProfile?.permacartTriggersInNDays
                      }
                      user={userProfile}
                      userType={user}
                    />
                  </CormieSection>
                </Col>
              </Row>
              {/* User billing row */}
              <Row className="mt-5">
                <Col className="col-12 col-sm-6">
                  <h1 id={"userBilling"}>{"Billing"}</h1>
                </Col>
                <CormieProfileEditButton
                  className="mb-2 mb-sm-0"
                  editCallback={() => {
                    navigate(
                      user === "local"
                        ? "/profile/edit-profile/#userBilling"
                        : `/customers/${uid}/edit-${
                          user === "client" ? "customer" : "employee"
                        }/#userBilling`,
                    )
                  }}
                />
              </Row>
              <Row>
                <Col>
                  <CormieSection>
                    <UserBillingInformation user={userProfile} />
                  </CormieSection>
                </Col>
              </Row>
            </>
          )}
          {/* User Orders row */}
          <Row>
            <Col>
              <PageTitle>
                {(user === "local"
                  ? "Your"
                  : correctPlural(userProfile?.fullName || "")
                ) + " orders"}
              </PageTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <CormieSection>
                <OrdersInterface clientId={clientId} carerId={carerId} />
              </CormieSection>
            </Col>
          </Row>
        </>
      )}
    </CormiePageContainer>
  )
}

export default Profile
